function closePopup() {
    // const audioTag = document.getElementById("mainMenuSound");

    // fadeIn(audioTag, 100, () => {
    //     audioTag.play();
    // });

    // Сразу отключаем взаимодействие с элементами
    gsap.set(document.querySelectorAll(".popup__main *"), {
        "user-select": "none",
        "pointer-events": "none",
    });

    // Устанавливаем таймлайн анимации закрытия
    const closetl = gsap.timeline({
        onComplete: () => {
            gsap.set(".popup-wrapper", {
                display: "none",
            });

            // Симулируем нажатие клавиши Enter
            var enterEvent = new KeyboardEvent("keydown", {
                key: "Enter",
                code: "Enter",
                keyCode: 13, // Код клавиши 'Enter' - 13
                which: 13,
                bubbles: true,
                cancelable: true,
            });

            // Диспетчируем событие на нужный элемент
            document.dispatchEvent(enterEvent);
        },
    });

    // Мгновенно скрываем все диалоговые блоки, чтобы они не перекрывали интерфейс
    gsap.set(".dialog__lirycs-block", {
        display: "none",
    });

    // Анимации для плавного скрытия интерфейса
    closetl.to(".dialog__speaker", {
        x: -20,
        opacity: 0,
        duration: 0.2, // Ускоряем анимацию
    });

    closetl.to(".dialog__button", {
        x: -20,
        opacity: 0,
        duration: 0.2, // Ускоряем анимацию
    });

    closetl.to(".dialog", {
        opacity: 0,
        duration: 0.2, // Ускоряем анимацию
    });

    closetl.to(".popup", {
        opacity: 0,
        duration: 0.2, // Ускоряем анимацию
        onComplete: () => {
            gsap.set(".popup", {
                display: "none",
            });

            gsap.set(".popups", {
                display: "none",
            });
        },
    });

    // Удаление блоков диалога, если они существуют
    const blocks = document.querySelectorAll(".dialog__lirycs-block");
    if (blocks.length > 0) {
        blocks.forEach((block) => {
            block.remove();
        });
    }

    // Окончательное скрытие попапа и его обертки
    gsap.timeline({
        onComplete: () => {
            gsap.set(".popup-wrapper", {
                display: "none",
            });

            // Симулируем нажатие клавиши Enter
            var enterEvent = new KeyboardEvent("keydown", {
                key: "Enter",
                code: "Enter",
                keyCode: 13, // 'Enter' key code is 13
                which: 13,
                bubbles: true,
                cancelable: true,
            });

            document.dispatchEvent(enterEvent);
        },
    })
        .to(".popup", {
            duration: 0.2,
            opacity: 0,
        })
        .to(
            ".popup-wrapper",
            {
                duration: 0.3,
                opacity: 0,
            },
            0.1
        );
}
