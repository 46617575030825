document.addEventListener("DOMContentLoaded", () => {
    const preloaderBtn = document.querySelector(".preloader__btn");

    preloaderBtn.addEventListener("click", () => {
        const backgroundMusic = document.getElementById("mainMenuSound");
        backgroundMusic.play();
        gsap.to(".preloader", {
            opacity: 0,
            onComplete: function () {
                gsap.to(".preloader", {
                    display: "none",
                });
                gsap.to(".menu", {
                    "z-index": 998,
                    opacity: 1,
                });
            },
        });
    });
});
