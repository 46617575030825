function updateSpeedIndicator(speedCurrent, speedMax) {
    const rectUp = document.querySelector(".speed-container rect.speed-up");
    const rectDown = document.querySelector(".speed-container rect.speed-down");
    const maxHeight = 100;
    const barHeight = Math.abs(speedCurrent / speedMax) * maxHeight;
    gsap.set(rectUp, {
        height: speedCurrent > 0 ? barHeight : 0,
        y: maxHeight - barHeight,
    });
    gsap.set(rectDown, {
        height: speedCurrent > 0 ? 0 : barHeight,
    });

    //HEIMSE

    const speedArrow = document.querySelector(".speed-arrow");
    const speedPercentage = Math.abs((100 * speedCurrent) / speedMax) / 100;
    const angle = speedPercentage * 158;

    gsap.to(speedArrow, {
        rotation: angle,
        duration: 0.01,
    });
}

function updateButtonsStyle(pedalIsPressed, acceleration) {
    const goBtn = document.querySelector("#go-btn");
    const stopBtn = document.querySelector("#stop-btn");
    if (pedalIsPressed) {
        gsap.to(goBtn, {
            backgroundColor: acceleration > 0 ? "#5E8bA2" : "#ffffff",
        });
        gsap.to(stopBtn, {
            backgroundColor: acceleration > 0 ? "#ffffff" : "#5E8bA2",
        });
    } else {
        gsap.to([goBtn, stopBtn], {
            backgroundColor: "#ffffff",
        });
    }
}
