function collectUrls(obj) {
    let urls = [];
    function traverse(o) {
        for (let key in o) {
            if (typeof o[key] === "string" && o[key].startsWith("http")) {
                urls.push(o[key]);
            } else if (typeof o[key] === "object") {
                traverse(o[key]);
            }
        }
    }
    traverse(obj);
    return urls;
}

function preloadImages(urls, allImagesLoadedCallback) {
    var loadedCounter = 0;
    var toBeLoadedNumber = urls.length;
    urls.forEach(function (url) {
        preloadImage(url, function () {
            loadedCounter++;
            if (loadedCounter == toBeLoadedNumber) {
                allImagesLoadedCallback();
            }
        });
    });
    function preloadImage(url, anImageLoadedCallback) {
        var img = new Image();
        img.onload = anImageLoadedCallback;
        img.src = url;
    }
}
