function isMobile() {
    return window.matchMedia("(max-width: 767px)").matches;
}

let height = document.querySelector(".dialog__speaker").style.height;

window.addEventListener("load", () => {
    preloadImages(collectUrls(popups), function () {});
});

document.addEventListener("DOMContentLoaded", () => {
    const menuStartBtn = document.querySelector(".menu__btn_start");
    menuStartBtn.addEventListener("click", () => {
        showPopup("first");
        let tl = gsap.timeline({
            onComplete: () => {
                gsap.set(".menu", {
                    display: "none",
                });
                setTimeout(() => {
                    gsap.set("canvas", {
                        opacity: 1,
                    });
                }, 2000);
            },
        });

        tl.to(".menu", {
            opacity: 0,
        });
    });
});

document.addEventListener("DOMContentLoaded", () => {
    const aboutBtn = document.querySelector(
        "body > div.menu > div > div.sidemenu__btn-menu.btn-menu > div.sidemenu__start-menu > div:nth-child(3)"
    );

    const menu = document.querySelector(".menu");
    const game = document.querySelector(".game");

    if (aboutBtn && menu && game) {
        aboutBtn.addEventListener("click", () => {
            menu.style.display = "none";
            game.remove();

            if (typeof initFinal === "function") {
                initFinal();
            } else {
                console.error("initFinal is not defined");
            }
        });
    } else {
        console.error("One or more elements could not be found.");
    }
});
