function isSafari() {
    return (
        /Safari/.test(navigator.userAgent) &&
        !/Chrome/.test(navigator.userAgent)
    );
}

function fadeOut(audioElement, duration, callback) {
    if (isSafari()) {
        // Skip fading for Safari
        audioElement.volume = 0;
        if (callback) callback();
        return;
    }

    const initialVolume = audioElement.volume;
    const stepTime = 50; // time in ms between volume decreases
    const steps = duration / stepTime;
    const volumeStep = initialVolume / steps;

    const fadeOutInterval = setInterval(() => {
        if (audioElement.volume > volumeStep) {
            audioElement.volume = Math.max(0, audioElement.volume - volumeStep);
        } else {
            audioElement.volume = 0;
            clearInterval(fadeOutInterval);
            if (callback) callback();
        }
    }, stepTime);
}

function fadeIn(audioElement, duration) {
    if (isSafari()) {
        // Skip fading for Safari
        audioElement.volume = 1;
        return;
    }

    const targetVolume = 1;
    const stepTime = 50; // time in ms between volume increases
    const steps = duration / stepTime;
    const volumeStep = targetVolume / steps;

    audioElement.volume = 0;

    const fadeInInterval = setInterval(() => {
        if (audioElement.volume < targetVolume - volumeStep) {
            audioElement.volume = Math.min(
                targetVolume,
                audioElement.volume + volumeStep
            );
        } else {
            audioElement.volume = targetVolume;
            clearInterval(fadeInInterval);
        }
    }, stepTime);
}

function changeMusicSource(
    audioElement,
    newSource,
    fadeOutDuration,
    fadeInDuration
) {
    fadeOut(audioElement, fadeOutDuration, () => {
        audioElement.pause();
        audioElement.src = newSource;
        audioElement.load();

        const onCanPlayThrough = () => {
            audioElement.removeEventListener(
                "canplaythrough",
                onCanPlayThrough
            );
            fadeIn(audioElement, fadeInDuration);
            audioElement.play().catch((error) => {
                console.error(
                    "Audio playback failed after source change:",
                    error
                );
            });
        };

        audioElement.addEventListener("canplaythrough", onCanPlayThrough);
    });
}

const musicMap = [
    "https://cdn-uimedia.ru/static/utk/assets/music/news/0.mp3",
    "https://cdn-uimedia.ru/static/utk/assets/music/news/1.mp3",
    "https://cdn-uimedia.ru/static/utk/assets/music/news/2.mp3",
    "https://cdn-uimedia.ru/static/utk/assets/music/news/3.mp3",
    "https://cdn-uimedia.ru/static/utk/assets/music/news/4.mp3",
    "https://cdn-uimedia.ru/static/utk/assets/music/news/5.mp3",
    "https://cdn-uimedia.ru/static/utk/assets/music/news/6.mp3",
    "https://cdn-uimedia.ru/static/utk/assets/music/news/7.mp3",
    "https://cdn-uimedia.ru/static/utk/assets/music/news/8.mp3",
    "https://cdn-uimedia.ru/static/utk/assets/music/news/9.mp3",
    "https://cdn-uimedia.ru/static/utk/assets/music/news/10.mp3",
    "https://cdn-uimedia.ru/static/utk/assets/music/news/11.mp3",
    "https://cdn-uimedia.ru/static/utk/assets/music/news/12.mp3",
    "https://cdn-uimedia.ru/static/utk/assets/music/news/13.mp3",
    "https://cdn-uimedia.ru/static/utk/assets/music/news/14.mp3",
    "https://cdn-uimedia.ru/static/utk/assets/music/news/15.mp3",
    "https://cdn-uimedia.ru/static/utk/assets/music/news/16.mp3",
    "https://cdn-uimedia.ru/static/utk/assets/music/news/17.mp3",
];

document.addEventListener("DOMContentLoaded", () => {
    const menuStartBtn = document.querySelector(".menu__btn_start");
    menuStartBtn.addEventListener("click", () => {
        const backgroundMusic = document.getElementById("mainMenuSound");
        const newMusicSource = musicMap[0]; // Adjust as needed

        backgroundMusic
            .play()
            .then(() => {
                changeMusicSource(backgroundMusic, newMusicSource, 500, 500);
            })
            .catch((error) => {
                console.error("Audio playback failed initially:", error);
            });
    });
});

document.addEventListener("DOMContentLoaded", () => {
    function initChaptersListener() {
        // Define a mapping of button indices to the values they should set
        const buttonValues = {
            0: 0.13,
            1: 0.24,
            2: 0.415,
            3: 0.552,
            4: 0.69,
            5: 0.81,
            6: 0.94,
            // 8: 0.94,
        };

        // Select all buttons in the side menu chapters menu
        const buttons = document.querySelectorAll(
            ".sidemenu__chapters-menu .btn-menu__btn-small.menu__btn"
        );

        // Select the input field that you want to change
        const inputField = document.querySelector(
            '[aria-labelledby="lil-gui-name-1"]'
        );

        buttons.forEach((button, index) => {
            button.addEventListener("click", () => {
                if (index in buttonValues) {
                    // Set the value of the input field based on the mapping
                    inputField.value = buttonValues[index];

                    // Optionally, trigger the input event if needed to notify any listeners
                    inputField.dispatchEvent(new Event("input"));
                    document.querySelector(".menu").style.display = "none";
                    const newMusicSource = musicMap[0]; // Adjust as needed
                    const backgroundMusic =
                        document.getElementById("mainMenuSound");

                    backgroundMusic
                        .play()
                        .then(() => {
                            changeMusicSource(
                                backgroundMusic,
                                newMusicSource,
                                500,
                                500
                            );
                        })
                        .catch((error) => {
                            console.error(
                                "Audio playback failed initially:",
                                error
                            );
                        });
                }
            });
        });
    }

    // Call the function to initialize the listeners
    initChaptersListener();

    const menuChaptersBtn = document.querySelector(".menu__btn_chapters");
    const menuBackBtn = document.querySelector("#backbtn");
    menuChaptersBtn.addEventListener("click", () => {
        document.querySelector(".sidemenu__start-menu").style.display = "none";
        document.querySelector(".sidemenu__chapters-menu").style.display =
            "flex";
    });

    menuBackBtn.addEventListener("click", () => {
        document.querySelector(".sidemenu__start-menu").style.display = "flex";
        document.querySelector(".sidemenu__chapters-menu").style.display =
            "none";
    });
});
