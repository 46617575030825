document.addEventListener("DOMContentLoaded", () => {
    const navLinks = document.querySelectorAll(".menu__btn");
    const beep = document.getElementById("btnHoverSound");

    navLinks.forEach((link, i) => {
        if (i !== 0) {
            const clonedBeep = beep.cloneNode(true);
            clonedBeep.id = `beep-${i}`;
            link.parentElement.appendChild(clonedBeep);
        }
        link.dataset.beeper = i;
    });

    navLinks.forEach((link) => {
        link.addEventListener("mouseenter", () => {
            const beeperId = link.dataset.beeper;
            const beepToPlay = document.getElementById(`beep-${beeperId}`);
            if (beepToPlay) {
                beepToPlay.play();
            }
        });
    });

    beep.id = "beep-0";
});
