const popups = {
    first: {
        background:
            "https://uploads-ssl.webflow.com/667f1f60daa28ede3e97a260/66b602351c673c8105360292_popup0.jpg",
        speakers: {
            prefrase: {
                name: "Предисловие",
                avatar: "",
                dialogs: {
                    0: 'Главный герой Макс живет в опасном мире, где много лет назад произошел "банкротный апокалипсис", - некогда процветающие технологичные города превратились в депрессивные трущобы, населенные должниками. В пустынной Долине Наблюдателей орудуют банды Временного Управляющего, который охотится за "золотыми парашютами" разорившихся топ-менеджеров. Некоторые выжившие пытаются спастись в Конкурсном лагере, а бывшие "силы мира сего" безуспешно пытаются оспорить решения о привлечении к субсидиарной ответственности. Надежда на спасение появляется, когда наш герой случайно спасает ютэканца, жителя мифического города "ЮТК", где по легендам смогли обуздать банкротный вирус. ',
                },
            },
        },
    },
    port: {
        background:
            "https://uploads-ssl.webflow.com/667f1f60daa28ede3e97a260/66b604726a6fc03767954e22_06.png",
        speakers: {
            prefrase: {
                name: "Предисловие",
                avatar: "https://uploads-ssl.webflow.com/667f1f60daa28ede3e97a260/66b6058fc6f4db518939847f_pupa.png",
                dialogs: {
                    0: "Основатель города купил компанию, но не провел инвентаризацию. Большинство контейнеров оказались пустыми. Фирма обанкротилась, а сотрудники остались жить в контейнерах.",
                },
            },
            maks: {
                name: "Макс",
                avatar: "https://uploads-ssl.webflow.com/667f1f60daa28ede3e97a260/66b6058fd0349701ffb8b49e_maks.png",
                dialogs: {
                    2: "Нам нужна карта долины, мы хотим добраться в оазис ЮТК.",
                },
            },
            misterMaersk: {
                name: "Мистер Маерск",
                avatar: "https://uploads-ssl.webflow.com/667f1f60daa28ede3e97a260/66b6058eb05eb79120a39ec6_maers.png",
                dialogs: {
                    1: "Приветствую вас, путники. Меня зовут Мистер Маерск. В прошлом я успешный предприниматель, но после неудачной покупки бизнеса морского порта стал банкротом. Что вам нужно?",
                    3: "Карты нет. После присоединения Высшего арбитражного суда к Верховному на долину наложили санкции и нам прекратили поставлять отбеливатель для бумаги. Поэтому всё перешло в электронную систему ГАС НЕПравосудие, а у меня никак не получается зарегистрироваться на госуслугах. Но есть электронная карта в ЭлектроТауне. Там вам нужно найти хакера Ван Тао.",
                },
            },
        },
    },
    town: {
        background:
            "https://uploads-ssl.webflow.com/667f1f60daa28ede3e97a260/66b60235d98dba714f82f39f_popup2.jpg",
        speakers: {
            prefrase: {
                name: "Предисловие",
                avatar: "https://uploads-ssl.webflow.com/667f1f60daa28ede3e97a260/66b6058fc6f4db518939847f_pupa.png",
                dialogs: {
                    0: "Город “Красных хакеров”. Недавно хакеры удалили все упоминания об обычной хозяйственной деятельности и доказали, что все жители города фактически аффилированны между собой, так как все пользуются услугами ООО «Электроэнерго».",
                },
            },
            VanTao: {
                name: "Ван Тао",
                avatar: "https://uploads-ssl.webflow.com/667f1f60daa28ede3e97a260/66b6059095e16fb50b901ca2_vanTao.png",
                dialogs: {
                    2: "Что вам нужно?",
                    4: "Вы что, хотите препятствовать работе системы, чтобы вас привлекли по 14.13?",
                    6: "Хорошо. Но тогда вы возьмете меня с собой. Я могу рассказать, как минимизировать риски оспаривания сделок в ходе текущей деятельности. У меня есть ЭЦП для доступа в ГАС НЕПравосудие, там мы найдем карту. Но ноут у моего брата по имени Гонг Вей, который живет в “Кремнегорске”.",
                },
            },
            maks: {
                name: "Макс",
                avatar: "https://uploads-ssl.webflow.com/667f1f60daa28ede3e97a260/66b6058fd0349701ffb8b49e_maks.png",
                dialogs: {
                    1: "Ван Тао?",
                    3: "Нам нужно добраться до оазиса ЮТК. Для этого нужно взломать ГАС НЕПравосудие и скачать карту.",
                },
            },
            utk: {
                name: "Ютэканец",
                avatar: "https://uploads-ssl.webflow.com/667f1f60daa28ede3e97a260/667f5bcf0746e7957723de71_utkanian.png",
                dialogs: {
                    5: "Ну мы первый раз же, не страшно.",
                },
            },
        },
    },
    "hong kong": {
        background:
            "https://uploads-ssl.webflow.com/667f1f60daa28ede3e97a260/66b604144de7ca0ad7ae9306_03.png",
        speakers: {
            prefrase: {
                name: "Предисловие",
                avatar: "https://uploads-ssl.webflow.com/667f1f60daa28ede3e97a260/66b6058fc6f4db518939847f_pupa.png",
                dialogs: {
                    0: "Кремнегорск - это некогда процветающий “город будущего”, в котором царила цифровизация и новые технологии. Однажды произошла глобальная утечка данных, жителей начали необоснованно привлекать к субсидиарной ответственности и с тех пор Кремнегорск - это “город должников”. Повсюду орудуют налоговые маршалы и коллекторы.",
                },
            },
            VanTao: {
                name: "Ван Тао",
                avatar: "https://uploads-ssl.webflow.com/667f1f60daa28ede3e97a260/66b6059095e16fb50b901ca2_vanTao.png",
                dialogs: {
                    1: "Привет. Я привел друзей, которые хотят добраться до оазиса ЮТК целыми и невредимыми.",
                    7: "Я останусь с братом. Возьмите мой рюкзак, он поможет выжить в Пустыне.",
                },
            },
            GongVey: {
                name: "Гонг Вей",
                avatar: "https://uploads-ssl.webflow.com/667f1f60daa28ede3e97a260/66b6058e74a66d65277493d1_gongVay.png",
                dialogs: {
                    2: "Ничего не получится. Они за год наш некогда цветущий город превратили в “кредитную дыру”. Сейчас я помогаю местным оспаривать субсидиарную ответственность, но мне не хватает мощностей текущей справочно-правовых систем.",
                    4: "Спасибо, тогда и я помогу вам.",
                },
            },
            ukt: {
                name: "Ютэканец",
                avatar: "https://uploads-ssl.webflow.com/667f1f60daa28ede3e97a260/66b60590f688f0137047f429_utkanian.png",
                dialogs: {
                    3: "Я могу тебе помочь. Я расскажу тебе про всеми забытый принцип ограниченной ответственности.",
                    6: "Оазис спрятан внутри каньона. Для того чтобы попасть в каньон нужно пересечь Долину Наблюдателей.",
                },
            },
            description: {
                name: "",
                avatar: "https://uploads-ssl.webflow.com/667f1f60daa28ede3e97a260/66b6058fc6f4db518939847f_pupa.png",
                dialogs: {
                    5: "Гонг Вей через ЭЦП открывает карту и даже распечатывает ее на принтере.",
                },
            },
        },
    },
    "desert park": {
        background:
            "https://uploads-ssl.webflow.com/667f1f60daa28ede3e97a260/66b603d424c30ab4f590ba43_01.png",
        speakers: {
            prefrase: {
                name: "Предисловие",
                avatar: "https://uploads-ssl.webflow.com/667f1f60daa28ede3e97a260/66b6058fc6f4db518939847f_pupa.png",
                dialogs: {
                    0: "Долина Наблюдателей - так называют пустынное место, между жилыми массивами, которая населена “дорожными пиратами”. В народе их называют Наблюдатели, так как они живут на телевышках и высматривают путников, чтобы ограбить. Компании путников не удается обойти наблюдателей. Их останавливают. На переговоры к ним выходит главарь наблюдателей по прозвищу “Временный Управляющий”.",
                },
            },
            manager: {
                name: "Временный управляющий",
                avatar: "https://uploads-ssl.webflow.com/667f1f60daa28ede3e97a260/66b6058f1d1dc7bb7d3320f1_manager.png",
                dialogs: {
                    1: "Все, кто попадает в Долину, становится банкротами.",
                    6: "Хм, закон? На это у меня есть судебная практика по делам о банкротстве",
                    8: "Да любая.",
                    9: "Что там у вас дальше? Финансовый анализ? У меня нет специальных познаний, надо привлечь аудитора, вы готовы оплачивать его услуги?",
                    11: "Ага, а вот это действительно что-то стоящее - «золотой парашют». Я смотрю вы подготовились. Ступайте, чтобы я вас здесь больше не видел. Направляйтесь по этой дороге в Конкурсный лагерь.",
                    13: "Это дорога в финансовое оздоровление. По ней уже давно никто не ходит, потому что в конце ты всё равно попадаешь в Конкурсный лагерь, просто по этой дороге идти дольше.",
                },
            },
            ukt: {
                name: "Ютэканец",
                avatar: "https://uploads-ssl.webflow.com/667f1f60daa28ede3e97a260/66b60590f688f0137047f429_utkanian.png",
                dialogs: {
                    2: "Это против правил.",
                },
            },
            description: {
                name: "",
                avatar: "https://uploads-ssl.webflow.com/667f1f60daa28ede3e97a260/66b6058fc6f4db518939847f_pupa.png",
                dialogs: {
                    3: "Начинается перепалка. Герои спорят на неразборчивом юридическом.",
                    5: "Внутри оказывается закон о банкротстве, финансовый анализ, «золотой парашют»",
                },
            },
            maks: {
                name: "Макс",
                avatar: "https://uploads-ssl.webflow.com/667f1f60daa28ede3e97a260/66b6058fd0349701ffb8b49e_maks.png",
                dialogs: {
                    4: "У нас же есть рюкзак, давай посмотрим что там",
                    7: "Какая?",
                    10: "Понял вас. Следующий вопрос...",
                    12: "А что это за дорога? Если первая дорога ведет в Конурсный лагерь, то куда мы попадем по второй дороге?",
                },
            },
        },
    },
    camp: {
        background:
            "https://uploads-ssl.webflow.com/667f1f60daa28ede3e97a260/66b602353688c2cb4eba418c_popup5.jpg",
        speakers: {
            doc: {
                name: "Док",
                avatar: "https://uploads-ssl.webflow.com/667f1f60daa28ede3e97a260/66b6058ebe34472e634c207a_docx.png",
                dialogs: {
                    0: "Приветствую вас в нашем лагере. Здесь все готовятся к исключению из ЕГРЮЛ, пока мы продаём всё, что им очень дорого, за очень дёшево.",
                    2: "Тогда я не могу вас пропустить в оазис, я прекращаю ваш путь через конкурсный лагерь из-за отсутствия финансирования. Направляйтесь в Топск и навестите моего друга г-на Иванова, если вы будете ему полезны, то он покажет вам другую дорогу в Оазис.",
                },
            },
            maks: {
                name: "Макс",
                avatar: "https://uploads-ssl.webflow.com/667f1f60daa28ede3e97a260/66b6058fd0349701ffb8b49e_maks.png",
                dialogs: {
                    1: "Мы хотим попасть в оазис ЮТК, он находится за вашем лагерем. Но у нас, к сожалению, ничего нет.",
                },
            },
        },
    },
    "town 2": {
        background:
            "https://uploads-ssl.webflow.com/667f1f60daa28ede3e97a260/66b6023745b502a4fd7210a2_popup6.jpg",
        speakers: {
            prefrase: {
                name: "Предисловие",
                avatar: "https://uploads-ssl.webflow.com/667f1f60daa28ede3e97a260/66b6058fc6f4db518939847f_pupa.png",
                dialogs: {
                    0: "На пути в каньон герои попадают в город Топ-менеджерск (он же Топск или Менеджерские Топи). Топск - это город в котором живут бывшие руководители крупных компаний потерпевших банкротство. Топы живут в домах в колониальном стиле, но потерявших былую презентабельность. В городе есть суд, в котором Топы безуспешно пытаются оспорить результаты банкротства их компаний.",
                },
            },
            maks: {
                name: "Макс",
                avatar: "https://uploads-ssl.webflow.com/667f1f60daa28ede3e97a260/66b6058fd0349701ffb8b49e_maks.png",
                dialogs: {
                    1: "Г-Н Иванов? Нас к Вам направил Док из Конкурсного лагеря. Мы можем вам чем-то помочь? Мы хотим попасть в оазис ЮТК.",
                },
            },
            ivanov: {
                name: "Иванов",
                avatar: "https://uploads-ssl.webflow.com/667f1f60daa28ede3e97a260/66b6058e9a5852447f088189_ivanov.png",
                dialogs: {
                    2: "Ничего не получится, я уже сделал всё возможное. Я пытался оспаривать сделки в долине наблюдателей, подал заявление о включении текущих требований работников в РТК и уже год ожидаю запрос от КУ, чтобы передать ему печати, штампы и иные материальные ценности.",
                    4: "Да и вообще, меня в суде никто не слушает, говорят, что у меня что-то с лицом.",
                    6: "Да, да.",
                    8: "Отлично. Спасибо за помощь. Пойдемте, я покажу вам дорогу в Оазис.",
                },
            },
            ukt: {
                name: "Ютэканец",
                avatar: "https://uploads-ssl.webflow.com/667f1f60daa28ede3e97a260/66b60590f688f0137047f429_utkanian.png",
                dialogs: {
                    3: "Вы все делаете не так.",
                    5: "Наверно, вы не участвующее в процессе лицо.",
                    7: "Я могу вам помочь. Я напишу ходатайство о привлечении в деле о банкротстве, и суд обязан будет вас выслушать.",
                },
            },
        },
    },
    "last one": {
        background:
            "https://cdn.prod.website-files.com/667f1f60daa28ede3e97a260/66e0087e02662eeab94462bd_04.png",
        speakers: {
            prefrase: {
                name: "Предисловие",
                avatar: "https://uploads-ssl.webflow.com/667f1f60daa28ede3e97a260/66b6058fc6f4db518939847f_pupa.png",
                dialogs: {
                    0: "Оазис ЮТК - это закрытый город, в котором живут и работают Ютэканцы, предприниматели, находящиеся под защитой академии ЮТК. В большей части Пустыни ЮТК считается городом-призраком, так как неизвестно где он расположен и существует ли вообще. В городе открыли ЮТК Академию - образовательное учреждение, где изучают банкротство и разрабатывают пути восстановления “Дороги банкротства”.",
                },
            },
            ukt: {
                name: "Ютэканец",
                avatar: "https://uploads-ssl.webflow.com/667f1f60daa28ede3e97a260/66b60590f688f0137047f429_utkanian.png",
                dialogs: {
                    1: "Я принес вам Закон о банкротстве!",
                    3: "За день?",
                },
            },
            scientist: {
                name: "Ученый №АN1933711",
                avatar: "https://cdn.prod.website-files.com/667f1f60daa28ede3e97a260/66d726a8cc4b900e1041f5d7_Group%2017.png",
                dialogs: {
                    2: "Так пока вы шли, уже 7 редакций сменилось.",
                    4: "Да. Скажите спасибо, что всего 17 пленумов за это вышло. Хорошо, что вы пришли, мы как раз закончили книгу «Правила банкротной безопасности».",
                },
            },
        },
    },
};
