document.addEventListener("DOMContentLoaded", () => {
    const radioNext = document.querySelector(".radio-top__next");
    const radioStop = document.querySelector(".radio-top__pause");
    const radioPrev = document.querySelector(".radio-top__prev");
    const audioTag = document.getElementById("mainMenuSound");

    audioTag.removeAttribute("loop"); // Remove loop attribute

    // Define music playlist
    const musicMap = [
        "https://cdn-uimedia.ru/static/utk/assets/music/news/0.mp3",
        "https://cdn-uimedia.ru/static/utk/assets/music/news/1.mp3",
        "https://cdn-uimedia.ru/static/utk/assets/music/news/2.mp3",
        "https://cdn-uimedia.ru/static/utk/assets/music/news/3.mp3",
        "https://cdn-uimedia.ru/static/utk/assets/music/news/4.mp3",
        "https://cdn-uimedia.ru/static/utk/assets/music/news/5.mp3",
        "https://cdn-uimedia.ru/static/utk/assets/music/news/6.mp3",
        "https://cdn-uimedia.ru/static/utk/assets/music/news/7.mp3",
        "https://cdn-uimedia.ru/static/utk/assets/music/news/8.mp3",
        "https://cdn-uimedia.ru/static/utk/assets/music/news/9.mp3",
        "https://cdn-uimedia.ru/static/utk/assets/music/news/10.mp3",
        "https://cdn-uimedia.ru/static/utk/assets/music/news/11.mp3",
        "https://cdn-uimedia.ru/static/utk/assets/music/news/12.mp3",
        "https://cdn-uimedia.ru/static/utk/assets/music/news/13.mp3",
        "https://cdn-uimedia.ru/static/utk/assets/music/news/14.mp3",
        "https://cdn-uimedia.ru/static/utk/assets/music/news/15.mp3",
        "https://cdn-uimedia.ru/static/utk/assets/music/news/16.mp3",
        "https://cdn-uimedia.ru/static/utk/assets/music/news/17.mp3",
    ];

    let currentTrackIndex = 1;

    function playAudio(source, fadeOutDuration = 100, fadeInDuration = 10) {
        changeMusicSource(audioTag, source, fadeOutDuration, fadeInDuration);
    }

    function playNext() {
        if (musicMap.length === 0) return; // Handle empty playlist

        if (currentTrackIndex >= musicMap.length) {
            currentTrackIndex = 0; // Reset to start if at end
        }

        const source = musicMap[currentTrackIndex];
        playAudio(source);
        currentTrackIndex++;
    }

    function playPrev() {
        if (musicMap.length === 0) return; // Handle empty playlist

        if (currentTrackIndex <= 0) {
            currentTrackIndex = musicMap.length - 1; // Move to last track
        } else {
            currentTrackIndex -= 1;
        }

        const source = musicMap[currentTrackIndex];
        playAudio(source);
    }

    audioTag.addEventListener("ended", playNext);

    radioNext.addEventListener("click", playNext);
    radioPrev.addEventListener("click", playPrev);

    radioStop.addEventListener("click", () => {
        if (!audioTag.paused) {
            fadeOut(audioTag, 100, () => {
                audioTag.pause();
            });
        } else {
            fadeIn(audioTag, 100);
        }
    });
});
