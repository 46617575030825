function showPopup(popupID) {
    // const audioTag = document.getElementById("mainMenuSound");

    // fadeOut(audioTag, 100, () => {
    //     audioTag.pause();
    // });

    if (popupID == "first") {
        gsap.set(document.querySelector(".dialog__speaker"), {
            height: "7.75rem",
        });

        if (isMobile()) {
            gsap.set(document.querySelector("#dialog__lirycs-wrapper"), {
                inset: "auto 0% 27.8rem",
            });
        }
    } else {
        gsap.set(document.querySelector(".dialog__speaker"), {
            height: "18.75rem",
        });

        if (isMobile()) {
            gsap.set(document.querySelector("#dialog__lirycs-wrapper"), {
                inset: "auto 0% 17.8rem",
            });
            gsap.set(document.querySelector(".dialog__speaker"), {
                inset: "auto 0% 19.5rem auto",
            });
        }
    }

    gsap.set(document.querySelectorAll(".popup__main *:not(#dialogButton)"), {
        "user-select": "unset",
        "pointer-events": "unset",
    });

    gsap.set(".dialog__speaker", {
        x: -20,
        opacity: 0,
    });
    const popupKeys = Object.keys(popups);
    document
        .querySelector(".popup__background-image")
        .setAttribute("src", popups[popupID].background);

    document
        .querySelector(".popup__background-image")
        .setAttribute("srcset", "");

    createDialogLyricsBlocks(popupID, "dialog__lirycs-wrapper");
    handleDialogButton(popupID, "dialog__lirycs-wrapper", "dialogButton");

    const openTl = gsap.timeline();

    openTl.to(".popups", {
        display: "block",

        onComplete: () => {
            gsap.to(`.popup`, {
                display: "block",
                opacity: 1,
            });
        },
    });

    openTl.fromTo(
        ".dialog",
        {
            opacity: 0,
        },
        {
            opacity: 1,
        }
    );

    openTl.fromTo(
        ".dialog__speaker",
        {
            x: -20,
            opacity: 0,
        },
        {
            x: 0,
            opacity: 1,
        }
    );

    openTl.fromTo(
        ".dialog__button",
        {
            x: -20,
            opacity: 0,
        },
        {
            x: 0,
            opacity: 1,
        }
    );

    // Check if this is the last popup and remove all canvas elements
    setTimeout(() => {
        if (popupKeys.indexOf(popupID) === popupKeys.length - 1) {
            // This is the last popup
            document
                .querySelectorAll("canvas")
                .forEach((canvas) => canvas.remove());
            document.querySelector(".game").style.opacity = 0;
            document.querySelector(".menu").style.display = "none";

            document
                .querySelectorAll(".gamerender")
                .forEach((render) => render.remove());
            const final = document.querySelector(".final");
            gsap.set(final, { display: "none" });
        }
    }, 2000);
}

function fadeOutDialog(element, duration = 0.2, avatar) {
    disableButton();
    return new Promise((resolve) => {
        gsap.fromTo(
            ".dialog__speaker",
            {
                x: 0,
                opacity: 1,
                duration: duration,
            },
            {
                x: -50,
                opacity: 0,
                onComplete: () => {
                    document
                        .querySelector(".dialog__speaker img")
                        .setAttribute("src", avatar);
                    resolve();
                },
            }
        );
        gsap.to(element, {
            opacity: 0,
            duration: duration,
            onComplete: () => {
                setInterval(() => {
                    enableButton();
                }, 2000);
            },
        });
    });
}

function fadeInDialog(element, duration = 0.2) {
    disableButton();
    return new Promise((resolve) => {
        gsap.fromTo(
            ".dialog__speaker",
            { x: -50, opacity: 0, duration: duration },
            {
                x: 0,
                opacity: 1,
                onComplete: () => {
                    resolve();
                },
            }
        );
        gsap.to(element, {
            opacity: 1,
            duration: duration,
            onComplete: () => {
                setInterval(() => {
                    enableButton();
                }, 2000);
            },
        });
    });
}

function handleDialogButton(popupName, containerId, buttonId) {
    // Ensure the popup exists in the popups object
    if (!popups[popupName]) {
        console.error("Popup not found");
        return;
    }

    // Get the container element and the button element
    const container = document.getElementById(containerId);
    const button = document.getElementById(buttonId);
    if (!container || !button) {
        console.error("Container or button not found");
        return;
    }

    // Get all dialog blocks within the container
    const dialogBlocks = container.getElementsByClassName(
        "dialog__lirycs-block"
    );
    if (dialogBlocks.length === 0) {
        console.error("No dialog blocks found");
        return;
    }

    let currentDialogIndex = 0;
    document
        .querySelector(".dialog__speaker img")
        .setAttribute("src", getAvatar(popupName, 0));
    // Show the first dialog block
    fadeInDialog(dialogBlocks[currentDialogIndex]);

    button.addEventListener("click", async () => {
        if (currentDialogIndex < dialogBlocks.length - 1) {
            // Fade out the current dialog block
            await fadeOutDialog(
                dialogBlocks[currentDialogIndex],
                1,
                getAvatar(popupName, currentDialogIndex + 1)
            );

            // Move to the next dialog block
            currentDialogIndex++;

            // Fade in the next dialog block
            fadeInDialog(dialogBlocks[currentDialogIndex]);
        } else {
            const popupKeys = Object.keys(popups);
            if (popupKeys.indexOf(popupName) < popupKeys.length - 1) {
                await fadeOutDialog(
                    dialogBlocks[currentDialogIndex],
                    1,
                    getAvatar(popupName, currentDialogIndex + 1)
                );

                currentDialogIndex = 0;
                closePopup();
            } else {
                gsap.to(".popup", {
                    opacity: 0,

                    onComplete: () => {
                        initFinal();
                    },
                });
            }
        }
    });
}

function getAvatar(popupId, dialogIndex) {
    const popup = popups[popupId];
    if (!popup) {
        return null;
    }

    const speakers = popup.speakers;
    for (const speaker in speakers) {
        const dialogs = speakers[speaker].dialogs;
        if (dialogs.hasOwnProperty(dialogIndex)) {
            return speakers[speaker].avatar;
        }
    }

    return null;
}

function createDialogLyricsBlocks(popupName, containerId) {
    // Ensure the popup exists in the popups object
    if (!popups[popupName]) {
        console.error("Popup not found");
        return;
    }

    // Retrieve the speakers object for the specified popup
    const speakers = popups[popupName].speakers;

    // Get the container element where dialog blocks will be appended
    const container = document.getElementById(containerId);
    if (!container) {
        console.error("Container not found");
        return;
    }

    // Create an array to hold all dialog entries
    const dialogEntries = [];

    // Iterate through each speaker in the popup
    for (const speakerKey in speakers) {
        if (speakers.hasOwnProperty(speakerKey)) {
            const speaker = speakers[speakerKey];
            const dialogs = speaker.dialogs;

            // Iterate through each dialog of the current speaker
            for (const dialogOrder in dialogs) {
                if (dialogs.hasOwnProperty(dialogOrder)) {
                    const dialogText = dialogs[dialogOrder];

                    // Push the dialog entry into the array with order and speaker info
                    dialogEntries.push({
                        order: parseInt(dialogOrder),
                        speakerName: speaker.name,
                        dialogText: dialogText,
                    });
                }
            }
        }
    }

    // Sort dialog entries by their order
    dialogEntries.sort((a, b) => a.order - b.order);

    // Create and append dialog blocks in sorted order
    dialogEntries.forEach((entry) => {
        // Create the dialog__lirycs-block element
        const dialogBlock = document.createElement("div");
        dialogBlock.className = "dialog__lirycs-block";
        dialogBlock.style.opacity = 0;

        // Create and append the dialog__lirycs-header element
        const header = document.createElement("div");
        header.className = "dialog__lirycs-header";
        const headerText = document.createElement("p");
        headerText.className = "dialog__lirycs-header";
        headerText.textContent = entry.speakerName;
        header.appendChild(headerText);
        dialogBlock.appendChild(header);

        // Create and append the dialog__lirycs-body element
        const body = document.createElement("div");
        body.className = "dialog__lirycs-body";
        const bodyText = document.createElement("p");
        bodyText.className = "dialog__lyrics-p";
        bodyText.textContent = entry.dialogText;
        body.appendChild(bodyText);
        dialogBlock.appendChild(body);

        // Append the dialog block to the container
        container.appendChild(dialogBlock);
    });
}
