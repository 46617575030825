// i do not wanna do this anymore
function initFinal() {
    // Remove all canvas elements
    document.querySelectorAll("canvas").forEach((canv) => canv.remove());
    document.querySelectorAll(".game").forEach((canv) => canv.remove());
    document.querySelectorAll(".popup").forEach((div) => div.remove());
    // Hide the button and radio containers

    const final = document.querySelector(".final");
    const topLine = final.querySelector(".found__top-line");
    const bottomLine = final.querySelector(".found__bottom-line");
    const arrow = final.querySelector(".found__arrow-wrapper");
    const foundText = final.querySelector(".found__text-block");
    const foundFoundScreen = final.querySelector(".final__found");
    const foundSecondScreen = final.querySelector(".final__second");

    const creditsContainer = document.querySelector(".final__titles");
    const credits = creditsContainer.querySelectorAll(".title__block");

    // Define a GSAP timeline with default settings
    const foundTl = gsap.timeline({
        defaults: { duration: 0.6, ease: "power2.inOut" },
    });

    // Check if the device is mobile
    const isMobile = window.matchMedia("(max-width: 768px)").matches;

    // Set initial state for each element using gsap.set
    gsap.set(final, { opacity: 0, display: "flex", "pointer-events": "auto" });
    gsap.set(topLine, { scaleY: 0, transformOrigin: "top" });
    gsap.set(bottomLine, { scaleY: 0, transformOrigin: "top" });
    gsap.set(foundText, { opacity: 0 });
    gsap.set(arrow, { opacity: 0 });
    if (!isMobile) {
        gsap.set(foundFoundScreen, { y: 0 });
        gsap.set(foundSecondScreen, { top: "100vh" });
        // Set the initial position of the credits outside the viewport
        gsap.set(creditsContainer, { yPercent: 140 });
    }

    // Animate the final container's opacity
    gsap.to(final, {
        opacity: 1,
    });

    if (isMobile) {
        // Animate only the arrow and lines for mobile
        foundTl
            .fromTo(topLine, { scaleY: 0 }, { scaleY: 1 })
            .fromTo(foundText, { opacity: 0 }, { opacity: 1 })
            .fromTo(bottomLine, { scaleY: 0 }, { scaleY: 1 })
            .fromTo(arrow, { opacity: 0 }, { opacity: 1, duration: 0.2 });
    } else {
        // Define the full timeline animations for non-mobile devices
        foundTl
            .fromTo(topLine, { scaleY: 0 }, { scaleY: 1 })
            .fromTo(foundText, { opacity: 0 }, { opacity: 1 })
            .fromTo(bottomLine, { scaleY: 0 }, { scaleY: 1 })
            .fromTo(arrow, { opacity: 0 }, { opacity: 1, duration: 0.2 });

        // Additional animation sequence to change screens
        foundTl
            .fromTo(
                foundFoundScreen,
                { y: 0 },
                { y: "-100%", delay: 2 },
                "screenChangeLabel"
            )
            .fromTo(
                foundSecondScreen,
                { y: "0%" },
                { y: "-100%", delay: 2 },
                "screenChangeLabel"
            )
            // This marks the end of the non-repeating timeline
            .add(() =>
                startCreditsAnimation(creditsContainer, credits.length * 3.5)
            );
    }
}

// Function to start the infinite credits animation
function startCreditsAnimation(creditsContainer, duration) {
    gsap.timeline({ repeat: -1 }).fromTo(
        creditsContainer,
        { yPercent: 140 }, // Start position below the viewport
        {
            yPercent: -180, // End position above the viewport
            duration: duration, // Adjust speed based on the number of credit items
            ease: "linear",
        }
    );
}
